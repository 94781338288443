.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@font-face {
  font-family: 'universo';
  src: url('./assets/fonts/Fontspring-DEMO-rocgroteskcond-bold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'universo-thin';
  src: url('./assets/fonts/Fontspring-DEMO-rocgrotesk-regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'meta-pro'; /* You can choose any name for the font family */
  src: url('./assets/fonts/FFMetaProBook.TTF') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'meta-bold';
  src: url('./assets/fonts/FFMetaProBold.TTF');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'satoshi';
  src: url('./assets/fonts/Satoshi-Medium.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'satoshi-thin';
  src: url('./assets/fonts/Satoshi-Light.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

body {
  overflow-x: hidden;
  width: 100vw;
  
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



/* @media screen and (max-width: ) */